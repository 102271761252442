import { Fragment, useEffect, useState, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { authActions } from "../../store/auth-slice";

import Header from "../main/Header";
import Footer from "../main/Footer";
import TimeoutScreen from "../UI/TimeoutScreen";
import WarningScreen from "../UI/WarningScreen";

const Layout = () => {
  const dispatch = useDispatch();
  const setCookie = useCookies(["jwt"])[1];
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const [sessionStatus, setSessionStatus] = useState("safe"); // "safe", "warning", "timedout"
  const [timeLeft, setTimeLeft] = useState(60); // Countdown time in seconds

  const logoutTimerRef = useRef(null);
  const warningTimerRef = useRef(null);
  const countdownIntervalRef = useRef(null);

  useEffect(() => {
    if (!isLoggedIn) return;
    const clearAllTimers = () => {
      if (logoutTimerRef.current) clearTimeout(logoutTimerRef.current);
      if (warningTimerRef.current) clearTimeout(warningTimerRef.current);
      if (countdownIntervalRef.current)
        clearInterval(countdownIntervalRef.current);
    };

    const startWarningTimer = () => {
      warningTimerRef.current = setTimeout(() => {
        setSessionStatus("warning");
      }, 9 * 60 * 1000);
    };

    const startLogoutTimer = () => {
      logoutTimerRef.current = setTimeout(() => {
        setSessionStatus("timedout");
      }, 10 * 60 * 1000);
    };

    const resetTimers = () => {
      clearAllTimers();
      setSessionStatus("safe");
      startWarningTimer();
      startLogoutTimer();
    };

    const activityEvents = [
      "click",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
    ];

    activityEvents.forEach((event) => {
      window.addEventListener(event, resetTimers);
    });

    startWarningTimer();
    startLogoutTimer();

    return () => {
      clearAllTimers();
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetTimers);
      });
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (sessionStatus === "warning") {
      setTimeLeft(60);
      countdownIntervalRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdownIntervalRef.current);
            setSessionStatus("timedout");
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => {
        clearInterval(countdownIntervalRef.current);
      };
    }
  }, [sessionStatus]);

  useEffect(() => {
    if (sessionStatus === "timedout") {
      dispatch(authActions.logout());
      setCookie("jwt", "loggedout", { expires: new Date() });
      navigate("/login", { replace: true });
    }
  }, [sessionStatus, dispatch, navigate, setCookie]);

  return (
    <Fragment>
      <Header />
      <main>
        {sessionStatus === "safe" && <Outlet />}
        {sessionStatus === "warning" && <WarningScreen timeLeft={timeLeft} />}
        {sessionStatus === "timedout" && (
          <TimeoutScreen onContinue={setSessionStatus} />
        )}
      </main>
      <Footer />
    </Fragment>
  );
};

export default Layout;
