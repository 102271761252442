import { useState } from "react";
import { FcKey } from "react-icons/fc";
import { useDispatch } from "react-redux";
import useInput from "../../hooks/userInput";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import classes from "./CompleteLogin.module.css";
import { authActions } from "../../store/auth-slice";
import { alertActions } from "../../store/alert-slice";
import Spinner from "../UI/Spinner";
import { completeLogIn } from "../../api/api";

const CompleteLogin = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const setToken = useCookies(["jwt"])[1];
  const navigate = useNavigate();
  const {
    value: tokenInput,
    enteredValueIsValid: tokenInputIsValid,
    hasError: tokenInputIsInvalid,
    valueInputChangedHandler: tokenInputChangedHandler,
    valueInputBlurHandler: tokenInputBlurHandler,
    reset: tokenInputReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;
  if (tokenInputIsValid) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const res = await completeLogIn({ token: tokenInput });
    if (res.status === "success") {
      setToken("jwt", res.token);
      dispatch(authActions.login({ user: res.data.user }));
      dispatch(
        alertActions.setState({ message: res.message, status: res.status })
      );
      navigate("/account", { replace: true });
    } else {
      dispatch(alertActions.setState({ message: res.message, status: false }));
    }

    setShowSpinner(false);
    setTimeout(() => {
      dispatch(alertActions.resetState());
    }, 3000);
    tokenInputReset();
  };

  const tokenInputClasses = tokenInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;
  return (
    <form className={classes.form} onSubmit={submitHandler}>
      {showSpinner && <Spinner />}
      <div className={tokenInputClasses}>
        <label>Enter your 6-digit login token sent to you</label>
        <div className={classes["input-group"]}>
          <FcKey className={classes.icon} />
          <input
            type="text"
            value={tokenInput}
            onChange={tokenInputChangedHandler}
            onBlur={tokenInputBlurHandler}
          />
        </div>
      </div>
      <div className={classes.action}>
        <button disabled={!formIsValid} type="submit">
          Complete Login
        </button>
      </div>
    </form>
  );
};

export default CompleteLogin;
