import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FcBusinessman, FcDepartment, FcHome, FcLibrary } from "react-icons/fc";
import { BsCurrencyDollar } from "react-icons/bs";
import { IoLocation } from "react-icons/io5";
import { FcMediumPriority } from "react-icons/fc";
import useInput from "../../hooks/userInput";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import classes from "./International.module.css";
import Spinner from "../UI/Spinner";
import { transferActions } from "../../store/transfer-slice";
import { alertActions } from "../../store/alert-slice";
import { sendToken, getIbanDetails } from "../../api/api";

const International = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [amount, setAmount] = useState("0.00");
  const [bank_name, setBank_name] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const dispatch = useDispatch();
  const { jwt } = useCookies(["jwt"])[0];
  const sender = useSelector((state) => state.auth.user.accountNumber);
  const navigate = useNavigate();
  const amountRef = useRef(null);
  const remarkRef = useRef();

  const bankNameChangedHandler = (event) => {
    setBank_name(event.target.value);
  };

  const swiftChangedHandler = (event) => {
    setSwiftCode(event.target.value);
  };

  const bankAddressChangedHandler = (event) => {
    setBankAddress(event.target.value);
  };

  const {
    value: nameInput,
    enteredValueIsValid: nameInputIsValid,
    hasError: nameInputIsInvalid,
    valueInputChangedHandler: nameInputChangedHandler,
    valueInputBlurHandler: nameInputBlurHandler,
    // reset: nameInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: IBANInput,
    enteredValueIsValid: IBANInputIsValid,
    hasError: IBANInputIsInvalid,
    valueInputChangedHandler: IBANInputChangedHandler,
    valueInputBlurHandler: IBANInputBlurHandler,
    // reset: IBANInputReset,
  } = useInput((value) => value.trim() !== "");

  useEffect(() => {
    const fetchIban = async () => {
      const res = await getIbanDetails(IBANInput, jwt);
      if (res.status === "success") {
        setBank_name(res.data.response.data.bank.bank_name);
        setSwiftCode(res.data.response.data.bank.bic);
        setBankAddress(res.data.response.data.bank.address);
      } else {
        setBank_name("");
        setBankAddress("");
        setSwiftCode("");
      }
    };
    if (IBANInput.length > 15) {
      fetchIban();
    }
  }, [IBANInput, jwt]);

  const handleAmountChange = (e) => {
    const enteredValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters

    // Ensure the decimal part never exceeds 2 digits
    const decimalPart = enteredValue.slice(-2);
    const integerPart = enteredValue.slice(0, -2).replace(/^0+/, ""); // Remove leading zeros

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    const formattedValue = `${
      formattedIntegerPart.length === 0 ? "0" : formattedIntegerPart
    }.${decimalPart}`;

    setAmount(formattedValue);

    // Set caret position to the end
    if (amountRef.current) {
      amountRef.current.setSelectionRange(
        formattedValue.length,
        formattedValue.length
      );
    }
  };

  let formIsValid = false;
  if (
    bank_name.trim() !== "" &&
    nameInputIsValid &&
    IBANInputIsValid &&
    swiftCode.trim() !== "" &&
    amount !== "0.00"
  ) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const transactionInfo = {
      kind: "International",
      type: "debit",
      bankName: bank_name,
      receipientName: nameInput,
      receipient: IBANInput,
      amount: amount.replace(/,/g, ""),
      sender,
      swift: swiftCode,
      remark: remarkRef.current.value,
    };

    const res = await sendToken(jwt);

    if (res.status === "success") {
      dispatch(transferActions.transfer({ transactions: transactionInfo }));
      navigate("/account/confirmInternational");
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }

    setShowSpinner(false);
  };

  const nameInputClasses = nameInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;
  const IBANInputClasses = IBANInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  return (
    <form className={classes.form} onSubmit={submitHandler}>
      {showSpinner && <Spinner />}
      <div className={IBANInputClasses}>
        <label>IBAN</label>
        <div className={classes["input-group"]}>
          <FcDepartment className={classes.icon} />
          <input
            type="text"
            value={IBANInput}
            onChange={IBANInputChangedHandler}
            onBlur={IBANInputBlurHandler}
          />
        </div>
        {IBANInputIsInvalid && <span>Please enter valid IBAN</span>}
      </div>
      <div className={classes.group}>
        <label>Bank name</label>
        <div className={classes["input-group"]}>
          <FcHome className={classes.icon} />
          <input
            type="text"
            value={bank_name}
            onChange={bankNameChangedHandler}
            disabled={true}
          />
        </div>
      </div>
      <div className={classes.group}>
        <label>Swift/Sortcode</label>
        <div className={classes["input-group"]}>
          <FcLibrary className={classes.icon} />
          <input
            type="text"
            value={swiftCode}
            onChange={swiftChangedHandler}
            disabled={true}
          />
        </div>
      </div>
      <div className={classes.group}>
        <label>Bank address</label>
        <div className={classes["input-group"]}>
          <IoLocation className={classes.icon} />
          <input
            type="text"
            value={bankAddress}
            onChange={bankAddressChangedHandler}
            disabled={true}
          />
        </div>
      </div>
      <div className={nameInputClasses}>
        <label>Beneficiary name</label>
        <div className={classes["input-group"]}>
          <FcBusinessman className={classes.icon} />
          <input
            type="text"
            value={nameInput}
            onChange={nameInputChangedHandler}
            onBlur={nameInputBlurHandler}
          />
        </div>
        {nameInputIsInvalid && <span>Please enter beneficiary name</span>}
      </div>
      <div className={classes.group}>
        <label>Remark</label>
        <div className={classes["input-group"]}>
          <FcMediumPriority className={classes.icon} />
          <input type="text" placeholder="Optional" ref={remarkRef} />
        </div>
      </div>

      <div className={classes.group}>
        <label>Amount</label>
        <div className={classes["input-group"]}>
          <BsCurrencyDollar className={classes.icon} />
          <input
            type="text"
            value={amount}
            onChange={handleAmountChange}
            ref={amountRef}
          />
        </div>
        {/* {amountInputIsInvalid && <span>Please enter amount</span>} */}
      </div>
      <div className={classes.action}>
        <button type="submit" disabled={!formIsValid}>
          Next
        </button>
      </div>
    </form>
  );
};

export default International;
