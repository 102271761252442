import classes from "./History.module.css";
import { formartCurrencyUK } from "../../util/utility";

const History = (props) => {
  const date = new Date(props.date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <div className={classes.history}>
      <div className={classes.info}>
        <span>Id</span>
        <span>{props.id}</span>
      </div>
      <div className={classes.info}>
        <span>Transaction mode</span>
        <span>{props.kind}</span>
      </div>
      {props.type && (
        <div className={classes.info}>
          <span>Transaction type</span>
          <span>{props.type}</span>
        </div>
      )}
      {props.kind !== "In-person" && (
        <div className={classes.info}>
          <span>Beneficiary bank</span>
          <span>{props.bankName}</span>
        </div>
      )}
      {props.type === "debit" && (
        <div className={classes.info}>
          <span>Debited account</span>
          <span>{props.sender}</span>
        </div>
      )}
      {props.type === "credit" && (
        <div className={classes.info}>
          <span>Credited account</span>
          <span>{props.receipient}</span>
        </div>
      )}
      <div className={classes.info}>
        <span>Amount</span>
        <span>${formartCurrencyUK(props.amount)}</span>
      </div>
      <div className={classes.info}>
        <span>Date</span>
        <span>{date}</span>
      </div>
      <div className={classes.info}>
        <span>Progress</span>
        <span>{props.status ? "Completed" : "On hold"}</span>
      </div>
      {props.reversed && (
        <div className={classes.info}>
          <span>Status</span>
          <span>{props.reversed ? "Reversed" : ""}</span>
        </div>
      )}
    </div>
  );
};

export default History;
