import { Fragment } from "react";
import { Helmet } from "react-helmet-async";

import classes from "./Help.module.css";
import { bankName } from "../../util/config";

const Help = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Help</title>
        <meta name="description" content="" />
        <link rel="canonical" href="/help" />
      </Helmet>
      <section className={classes.help}>
        <div className={classes.description}>
          <h2>Take the hassle out of buying insurance</h2>
          <p>
            Choose 3 or more different types of cover to protect the people and
            things you love, all under one policy. Pay just one monthly premium
            - and cancel anytime. It's insurance made easy, and exclusive to{" "}
            {bankName} customers registered for online banking.
          </p>
        </div>
        <div className={classes.description}>
          <h2>Money worries</h2>
          <p>
            If you've missed a bill or payment, or think you might, it's
            important you let us know as soon as possible. We may be able to
            offer you support and help you better manage your finances.
          </p>
        </div>
        <div className={classes.description}>
          <h2>Help with your mortgage payments</h2>
          <p>
            If you're worried about rising interest rates, here are some ways
            you might be able to reduce your mortgage payments.
          </p>
        </div>
        <div className={classes.description}>
          <h2>What does the rising cost of living mean for students?</h2>
          <p>
            Here, we share insights into how students have been affected by the
            rising cost of living, plus tips to help you manage your money and
            get support if you need it.
          </p>
        </div>
      </section>
    </Fragment>
  );
};

export default Help;
