import classes from "./WarningScreen.module.css";

const WarningScreen = ({ timeLeft }) => {
  return (
    <div className={classes.warning}>
      <h4>
        Session will time out in{" "}
        <span className={classes.timing}>{timeLeft}</span> seconds due to
        inactivity.
      </h4>
      <p>Please interact with the application to stay logged in.</p>
    </div>
  );
};

export default WarningScreen;
