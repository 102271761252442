import classes from "./TimeoutScreen.module.css";

const TimeoutScreen = ({ onContinue }) => {
  return (
    <div className={classes.timeout}>
      <div>
        <h4>
          Session timedout after 10 minutes of inactivity, please continue to
          login
        </h4>
      </div>
      <button onClick={() => onContinue("safe")}>Continue</button>
    </div>
  );
};

export default TimeoutScreen;
