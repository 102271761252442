import { Link } from "react-router-dom";

import classes from "./Footer.module.css";
import approvedby1 from "../../images/validation1.png";
import approvedby2 from "../../images/validation2.png";
import { bankName } from "../../util/config";

const Footer = () => {
  return (
    <section className={classes.footer}>
      <div className={classes["footer-texts"]}>
        <Link to="/">
          <address>Email: support@privateskyheight.com</address>
        </Link>
        <address>117 Triq L-Arcisqof, IL-Belt Valletta, Malta</address>
        <p>
          {bankName} Bank PLC is authorised by the Prudential Regulation
          Authority and regulated by the Financial Conduct Authority and the
          Prudential Regulation Authority.
        </p>
        <p>
          {bankName} Insurance Services Company Limited and {bankName}
          Investment Solutions Limited are each authorised and regulated by the
          Financial Conduct Authority.
        </p>
      </div>
      <div className={classes.footerimg}>
        <img src={approvedby1} alt="fscs" />
        <img src={approvedby2} alt="bsi" />
      </div>
      <p>
        {bankName} Group | @ Copyright {bankName} Group 2024. All rights
        reserved
      </p>
    </section>
  );
};

export default Footer;
